import { styled } from '@mui/system'
import Icon from '../../atoms/icon'
import { SwiperSlide } from 'swiper/react'

const shouldForwardProp = (prop) => !['action', 'aboveTheFold'].includes(prop)

const Container = styled('div', { shouldForwardProp })`
	position: relative;
	--swiper-navigation-size: 50px;
	height: ${({ aboveTheFold }) => (aboveTheFold ? '100%' : undefined)};
`

const NavButton = styled(Icon, { shouldForwardProp })`
	color: ${({ theme }) => theme.palette.common.white};
	background-color: ${({ theme }) => theme.palette.common.white};
	font-size: ${({ theme }) => theme.typography.pxToRem(50)};
	border: 1px solid transparent;
	color: ${({ theme }) => theme.palette.primary.main};
	padding: ${({ theme }) => theme.spacing(1.75)};
	${({ action }) =>
		action === 'prev'
			? `
					left: 0;
			  `
			: `
					right: 0;
			  `}

	&.swiper-button-disabled {
		display: none;
	}
`

const PaginationStyled = styled('div')`
	&.swiper-pagination-horizontal {
		bottom: 6px;
		width: auto;
		left: 50%;
		transform: translateX(-50%);
		${({ theme }) => theme.breakpoints.up('md')} {
			width: 100%;
			left: 0;
			transform: none;
		}
	}

	&.swiper-pagination-bullets {
		line-height: 0;
	}

	.swiper-pagination-bullet {
		background-color: transparent;
		height: auto;
		opacity: 1;
		padding: ${({ theme }) => theme.spacing(0.5, '25px')};
		width: auto;
		&:before {
			border: 1px solid #e0e0e0;
			border-radius: 50%;
			content: '';
			display: block;
			height: ${({ theme }) => theme.spacing(1.25)};
			width: ${({ theme }) => theme.spacing(1.25)};
		}
		&-active {
			cursor: default;
			&:before {
				background-color: #e0e0e0;
			}
		}
	}
`
const SwiperSlideStyled = styled(SwiperSlide, { shouldForwardProp })`
	display: ${({ aboveTheFold }) => (aboveTheFold ? 'flex' : undefined)};
`

export { Container, NavButton, PaginationStyled, SwiperSlideStyled }
